<script>
import { Personal, Business } from '@/components/Products';

export default {
    components: { Personal, Business },
    data() {
        return {
            componentActive: 'Personal',
            width: 1920,
        };
    },
    computed: {
        listRepresent() {
            const list = [
                {
                    id: 1,
                    img: 'tai-cau-truc',
                    title: this.$t('reconstruct'),
                    about: this.$t('weWillHelpYouRecapture'),
                },
                {
                    id: 2,
                    img: 'giai-phap-goi-von',
                    title: this.$t('capitalCallingSolution'),
                    about: this.$t('EfundXProvidesSolutions'),
                },
                {
                    id: 3,
                    img: 'dinh-gia-doanh-nghiep',
                    title: this.$t('businessValuation'),
                    about: this.$t('weProvideValuationSupport'),
                },
                {
                    id: 4,
                    img: 'tai-chinh-doanh-nghiep',
                    title: this.$t('businessFinance'),
                    about: this.$t('corporateFinanceExperts'),
                },
                {
                    id: 5,
                    img: 'xay-dung-va-phat-trien',
                    title: this.$t('brandBuilding'),
                    about: this.$t('brandStrategy'),
                },
                {
                    id: 6,
                    img: 'phap-ly-doanh-nghiep',
                    title: this.$t('corporateLegal'),
                    about: this.$t('theCorporateLegal'),
                },
            ];
            return list;
        },
        listService() {
            return [
                {
                    id: '1',
                    component: 'Personal',
                    label: this.$t('withInvestors'),
                },
                {
                    id: '2',
                    component: 'Business',
                    label: this.$t('withBusiness'),
                },
            ];
        },
    },
    methods: {
        onWindowResize() {
            this.width = window.innerWidth;
        },
    },
    created() {
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
};
</script>
<template>
    <b-container fluid class="product-services">
        <b-container class="banner">
            <img
                src="~@/assets/images/mock/project/repre.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('serviceProvided') }}</div>
                <div class="input">
                    <b-button>
                        <img src="~@/assets/images/icons/search.png" alt="" />
                    </b-button>
                    <b-form-input :placeholder="$t('search')"></b-form-input>
                </div>
            </div>
        </b-container>
        <b-container fluid>
            <div class="nav-services">
                <div
                    class="nav-item"
                    v-for="item in listService"
                    :key="`service-${item.component}`"
                    :class="
                        componentActive == item.component ? 'tab-active' : ''
                    "
                    @click="componentActive = item.component"
                >
                    {{ item.label }}
                </div>
            </div>
            <component :is="componentActive"></component>
        </b-container>
        <template v-if="componentActive == 'Business'">
            <b-container fluid>
                <b-row>
                    <b-col class="ecosystem-content">
                        <h3>{{ $t('ecosystem') }}</h3>
                        <img
                            v-if="width > 375"
                            src="~@/assets/images/mock/project/content-ecosystem.png"
                            alt=""
                        />
                        <img
                            v-else
                            src="~@/assets/images/mock/project/content-ecosystem-mobile.png"
                            alt=""
                        />
                    </b-col>
                </b-row>
            </b-container>
            <h3 class="text-uppercase">{{ $t('typicalProducts') }}</h3>
            <b-container
                fluid
                class="section-content"
                v-for="(represent, idx) in listRepresent"
                :key="`represent-${represent.id}`"
                :data-aos="idx % 2 === 0 ? 'fade-left' : 'fade-right'"
            >
                <b-container>
                    <b-row class="row-full">
                        <b-col cols="12" md="7" lg="6">
                            <div class="title">
                                {{ represent.title }}
                            </div>
                            <div class="description">
                                <p v-if="represent.about">
                                    <span v-html="represent.about"></span>
                                </p>
                            </div>
                        </b-col>
                        <b-col cols="12" md="5" lg="6">
                            <div class="project-item">
                                <div class="project-img">
                                    <img
                                        :src="
                                            require(`../../assets/images/mock/project/${represent.img}.png`)
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="project-name"></div>
                                <div class="project-line"></div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>
        </template>
        <template v-if="false">
            <b-container fluid class="section-content" data-aos="fade-up">
                <h3 class="text-center font-weight-bold text-uppercase mb-0">
                    Chưa có dữ án tiêu biểu
                </h3>
            </b-container>
        </template>
    </b-container>
</template>
<style lang="scss">
.product-services {
    padding: 50px 0;
    overflow: hidden;
    h3 {
        text-align: center;
        color: #003189;
        font-weight: 800;
    }
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (min-width: 1400px) {
            max-width: 1320px;
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
            .input {
                border: 1px solid #000093;
                background: rgba($color: #ffffff, $alpha: 0.4);
                height: 45px;
                width: 100%;
                max-width: 420px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                button {
                    flex: 0 0 60px;
                    height: 45px;
                    padding: 5px;
                    background: transparent;
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                input {
                    border: 0;
                    background: transparent;
                    box-shadow: none;
                    color: #fff;
                    font-weight: 500;
                    &::placeholder {
                        color: #fff;
                        opacity: 0.9;
                    }
                }
            }
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    .ecosystem-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0px;
        background-image: url('~@/assets/images/mock/project/bg-ecosystem.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-bottom: 50px;
        @media (max-width: 575px) {
            padding: 50px 5px;
        }
        h3 {
            margin-bottom: 50px;
        }
        img {
            width: 1000px;
            height: auto;
            @media (max-width: 1199px) {
                width: 900px;
            }
            @media (max-width: 991px) {
                width: 750px;
            }
            @media (max-width: 767px) {
                width: 550px;
            }
            @media (max-width: 575px) {
                width: 100%;
            }
        }
    }
    .nav-services {
        width: max-content;
        max-width: 100%;
        display: flex;
        justify-content: center;
        font-size: 20px;
        height: 50px;
        box-shadow: 0px 0px 5px #0000005a;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin: 40px auto 0;
        @media (max-width: 575px) {
            height: 40px;
            max-width: 100%;
            width: 100%;
        }
        .nav-item {
            max-width: max-content;
            min-width: 150px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #363795;
            font-weight: bold;
            padding: 0 15px;
            @media (max-width: 575px) {
                max-width: unset;
                // width: auto;
                // height: auto;
                font-size: 16px;
                padding: 0 10px;
                min-width: unset;
                line-height: 110%;
            }
            @media (max-width: 575px) {
                max-width: unset;
                // width: auto;
                // height: auto;
                font-size: 14px;
                line-height: 110%;
                padding: 0 10px;
                min-width: unset;
            }
            &.tab-active {
                background-image: linear-gradient(to right, #363795, #0040ff);
                color: #ffffff;
            }
            cursor: pointer;
            &:first-child {
                border-top-left-radius: 20px;
            }
            &:last-child {
                border-top-right-radius: 20px;
            }
        }
    }
    .section-content {
        padding: 20px 0;

        &:nth-child(odd) {
            background-color: #f7f7f7;
        }
        @media (min-width: 768px) {
            &:nth-child(odd) {
                .row-full {
                    .col-md-7:first-child {
                        order: 2;
                        .title {
                            &:before {
                                right: auto;
                                left: 0;
                            }
                            &:after {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                    .project-item {
                        margin: 20px auto 20px 0;
                    }
                }
            }
            &:nth-child(even) {
                .row-full {
                    .project-item {
                        margin: 20px 0 20px auto;
                    }
                }
            }
        }
        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title-section {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 35px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    height: 4px;
                    width: calc(100% - 10px);
                    background: #003189;
                }
                // &:after {
                // }
                &:before {
                    right: 0;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #003189;
                    bottom: 0;
                }
            }
            .description {
                p {
                    font-weight: 400;
                    font-size: 18px;
                    span {
                        &:nth-child(1) {
                            font-weight: 600;
                        }
                    }
                }
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                max-width: 375px;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }
                .project-img {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
                .project-name {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    p {
                        text-align: center;
                        display: -webkit-box;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
                .project-line {
                    width: 100%;
                    height: 8px;
                    background-color: #363795;
                    border-radius: 10px;
                }
            }
        }
    }
    @media (max-width: 575px) {
        padding: 25px 10px;
        .banner {
            min-height: 250px;
        }
    }
}
</style>
