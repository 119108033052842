<template>
    <b-container fluid class="p-0 personal">
        <b-container class="box-1 box">
            <b-row class="row-full">
                <b-col cols="12" md="6" lg="5">
                    <div class="project-item">
                        <div class="project-img">
                            <img
                                :src="
                                    require(`@/assets/images/mock/services/box-1.png`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="project-name"></div>
                        <div class="project-line"></div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="7">
                    <div class="title">
                        {{ $t('capitalManagementForInvestors') }}
                    </div>
                    <div class="description">
                        <p>
                            1. {{ $t('manageCashFlow') }}<br />
                            2. {{ $t('evaluatingTheEfficiency') }}<br />
                            3. {{ $t('consultingOnManagement') }}
                            <br />
                            4. {{ $t('predictFinancialRisks') }}<br />
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid class="box box-2">
            <b-container class="box-content">
                <div class="title-box">{{ $t('ourSolution') }}</div>
                <div class="list">
                    <div
                        class="item"
                        v-for="solution in listSolution"
                        :key="`solution-${solution.title}`"
                    >
                        <div class="header-box">{{ solution.title }}</div>
                        <div class="body-box">
                            <p v-html="solution.content"></p>
                        </div>
                        <div class="icons-box">
                            <img
                                :src="
                                    require(`@/assets/images/mock/services/${solution.icons}.png`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="line-bottom"></div>
                    </div>
                </div>
            </b-container>
        </b-container>
        <b-container class="box box-3">
            <div class="nav-services">
                <div
                    class="nav-item"
                    v-for="item in listService"
                    :key="`service-${item.component}`"
                    :class="
                        componentActive == item.component ? 'tab-active' : ''
                    "
                    @click="componentActive = item.component"
                >
                    {{ item.label }}
                </div>
            </div>
            <!-- <a href="#"> GÓI ĐẦU TƯ THỰC ĐỊA </a> -->
            <!-- box-content -->
            <div class="box-content2" v-if="componentActive == 'Field'">
                <div class="left">
                    <div class="top">
                        <div class="content-left">
                            {{ $t('characteristic') }}
                        </div>
                        <div class="content-right">
                            <div class="colum-2">
                                <p>
                                    -
                                    {{ $t('investmentProjectsAtTheSameTime') }}
                                </p>
                                <p>- {{ $t('responsibleForAppraising') }}</p>
                                <p>- {{ $t('fieldEnterprisesReportData') }}</p>
                                <p>
                                    - {{ $t('directlyExperiencesInvestment') }}
                                </p>
                                <p>- {{ $t('certainFundingPeriod') }}</p>
                                <p>- {{ $t('dependsOnTheBusiness') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <div class="content-left">{{ $t('initialTerm') }}</div>
                        <div class="content-right">
                            <div class="colum-2">
                                <p>{{ $t('receiveUntil') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="content-left">
                            {{ $t('investmentPackages') }}
                        </div>
                        <div class="content-right">
                            <div class="colum-1">
                                <div class="header-col">
                                    {{ $t('Investment') }}
                                </div>
                                <div class="content">
                                    <div class="item">
                                        {{ $t('price1') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('price2') }}
                                    </div>
                                    <div class="item">{{ $t('price3') }}</div>
                                </div>
                            </div>
                            <div class="colum-2 PIF">
                                <div class="header-col">
                                    {{ $t('totalRevenue') }}
                                </div>
                                <div class="content">
                                    <div class="item">~ 200%</div>
                                    <div class="item">~ 220%</div>
                                    <div class="item">~ 250%</div>
                                </div>
                            </div>
                            <div class="colum-3">
                                <div class="header-col">
                                    {{ $t('averageMonthlyRevenue') }}
                                </div>
                                <div class="content">
                                    <div class="item">
                                        {{ $t('aboutPercent1') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('aboutPercent2') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('aboutPercent3') }}
                                    </div>
                                </div>
                            </div>
                            <div class="colum-4">
                                <div class="header-col">
                                    {{ $t('managementFee') }}
                                </div>
                                <div class="content">
                                    <div class="item">15%</div>
                                    <div class="item">15%</div>
                                    <div class="item">15%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img
                        src="~@/assets/images/logo/logo-text-light.png"
                        alt=""
                        class="logo"
                    />
                    <img
                        src="~@/assets/images/mock/services/box-3.png"
                        alt=""
                        class="mock"
                    />
                </div>
            </div>
            <div class="box-content2" v-if="componentActive == 'PIF'">
                <div class="left">
                    <div class="top">
                        <div class="content-left">
                            {{ $t('characteristic') }}
                        </div>
                        <div class="content-right">
                            <div class="colum-2">
                                <p>- {{ $t('reservesTheRight') }}</p>
                                <p>- {{ $t('fieldEnterprisesReportData') }}</p>
                                <p>- {{ $t('callingTime') }}</p>
                                <p>- {{ $t('commitmentOfLiability') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <div class="content-left">{{ $t('initialTerm') }}</div>
                        <div class="content-right">
                            <div class="colum-2">
                                <p>{{ $t('receiveUntil') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="content-left">
                            {{ $t('investmentPackages') }}
                        </div>
                        <div class="content-right">
                            <div class="colum-1">
                                <div class="header-col">
                                    {{ $t('Investment') }}
                                </div>
                                <div class="content">
                                    <div class="item">
                                        {{ $t('price1') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('price2') }}
                                    </div>
                                    <div class="item">{{ $t('price3') }}</div>
                                </div>
                            </div>
                            <div class="colum-2 PIF">
                                <div class="header-col">
                                    {{ $t('totalRevenue') }}
                                </div>
                                <div class="content">
                                    <div class="item">~ 200%</div>
                                    <div class="item">~ 220%</div>
                                    <div class="item">~ 250%</div>
                                </div>
                            </div>
                            <div class="colum-3">
                                <div class="header-col">
                                    {{ $t('averageMonthlyRevenue') }}
                                </div>
                                <div class="content">
                                    <div class="item">
                                        {{ $t('aboutPercent1') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('aboutPercent2') }}
                                    </div>
                                    <div class="item">
                                        {{ $t('aboutPercent3') }}
                                    </div>
                                </div>
                            </div>
                            <div class="colum-4">
                                <div class="header-col">
                                    {{ $t('managementFee') }}
                                </div>
                                <div class="content">
                                    <div class="item">15%</div>
                                    <div class="item">15%</div>
                                    <div class="item">15%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img
                        src="~@/assets/images/logo/logo-text-light.png"
                        alt=""
                        class="logo"
                    />
                    <img
                        src="~@/assets/images/mock/services/box-3.png"
                        alt=""
                        class="mock"
                    />
                </div>
            </div>
            <div class="warning">
                <div class="title">{{ $t('investorInterests') }}</div>
                <p>- {{ $t('receiveMonthly') }}</p>
                <p>- {{ $t('GetAdvice') }}</p>
                <p>- {{ $t('monthlyRevenue') }}</p>
                <p>- {{ $t('investmentAssets') }}</p>
                <p>- {{ $t('atTheEndOfTheTerm') }}</p>
                <div class="title">{{ $t('investorResponsibility') }}</div>
                <p>- {{ $t('confidentialityOfNecessary') }}</p>
                <p>- {{ $t('complyWith') }}</p>
            </div>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'Personal',
    data() {
        return {
            componentActive: 'Field',
        };
    },
    computed: {
        listService() {
            return [
                {
                    id: '1',
                    component: 'Field',
                    label: this.$t('fieldInvestmentPackage'),
                },
                {
                    id: '2',
                    component: 'PIF',
                    label: this.$t('investmentpackageEfundX'),
                },
            ];
        },
        listSolution() {
            return [
                {
                    title: this.$t('mainIssues'),
                    content: `${this.$t('difficultToFind')} <br /><br />
                     ${this.$t('lackOfData')}`,
                    icons: '1',
                },

                {
                    title: this.$t('ourSolution'),
                    content: `${this.$t('concentrateResources')} <br /><br />
                    ${this.$t('buildingCommunity')}`,
                    icons: '2',
                },
                {
                    title: this.$t('improvementIndex'),
                    content: `${this.$t('riskReduction')} <br /><br />
                    ${this.$t('guaranteedNetInterest')} <br /><br />
                    ${this.$t('increaseQualityRelationships')}`,
                    icons: '3',
                },
                {
                    title: this.$t('sponsoredUnits'),
                    content: `${this.$t('angelInvestors')} <br /><br />
                    ${this.$t('secondaryInvestors')}`,
                    icons: '4',
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.personal {
    .box-1 {
        padding: 30px 5%;
        box-shadow: 1px 1px 5px #0000005a;
        border-radius: 25px;
        margin-bottom: 50px;
        &:hover {
            .project-img {
                transition: all 1s;
                img {
                    transform: scale(1.2);
                    transition: all 1s;
                }
            }
        }
        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title-section {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 29px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 15px;
                position: relative;
                padding-bottom: 15px;
                @media (max-width: 991px) {
                    font-size: 22px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 10%;
                    height: 4px;
                    width: calc(70% - 10px);
                    background: #003189;
                }
                // &:after {
                // }
                &:before {
                    right: 20%;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #003189;
                    bottom: 0;
                }
            }
            .description {
                p {
                    font-weight: 400;
                    font-size: 18px;
                    span {
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px 0;
                width: 100%;
                border-radius: 25px;
                max-width: 375px;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }

                .project-img {
                    position: relative;
                    overflow: hidden;
                    border-radius: 25px;
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .box-2 {
        background-color: #fbfdfd;
        padding: 50px 0;
        @media (min-width: 1200px) {
            max-width: 1050px;
        }
        .box-content {
            min-height: 450px;
            box-shadow: 5px 0px 15px 0px rgba($color: #000000, $alpha: 0.3);
            border-radius: 25px 25px 5px 5px;
            overflow: hidden;
            padding: 0;
            .title-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-family: 'Roboto', sans-serif;
                font-size: 30px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;
                .item {
                    min-height: 475px;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    min-width: 250px;
                    flex: 1;
                    position: relative;
                    box-shadow: 5px 0px 15px 0px
                        rgba($color: #000000, $alpha: 0.3);
                    &:nth-child(1) {
                        z-index: 4;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #3370de 0%,
                                #3370de 100%
                            );
                        }
                    }
                    &:nth-child(2) {
                        z-index: 3;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #004cd6 0%,
                                #004cd6 100%
                            );
                        }
                    }
                    &:nth-child(3) {
                        z-index: 2;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #0039a0 0%,
                                #0039a0 100%
                            );
                        }
                    }
                    &:nth-child(4) {
                        z-index: 1;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #00266b 0%,
                                #00266b 100%
                            );
                        }
                    }
                    .header-box {
                        height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: 'Roboto', sans-serif;
                        color: #fff;
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 0px 10px;
                        @media (max-width: 991px) {
                            font-size: 18px;
                        }
                        @media (max-width: 767px) {
                            font-size: 17px;
                        }
                    }
                    .body-box {
                        padding: 20px 15px;
                        min-height: 220px;
                        p {
                            text-align: left;
                            text-align-last: left;
                            line-height: 130%;
                            font-weight: 500;
                            font-size: 17px;
                        }
                    }
                    .icons-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: auto;
                            margin: auto;
                            height: 70px;
                        }
                    }
                    .line-bottom {
                        width: 100%;
                        height: 10px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
    .box-3 {
        padding: 0px 0px 50px 0px;
        @media (max-width: 991px) {
            max-width: calc(100% - 10px);
        }
        a {
            background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
            border: 2px solid #fff;
            text-decoration: none;
            transition: all 0.5s;
            margin: 20px auto;
            min-height: 50px;
            min-width: 130px;
            max-width: 300px;
            padding: 5px 10px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
            color: #fff;
            font-weight: 600;
            text-decoration: none;
            font-size: 18px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            box-shadow: 1px 2px 10px 2px rgba($color: #000000, $alpha: 0.3);

            &:hover {
                transform: scale(1.05);
            }
        }
        .nav-services {
            width: max-content;
            max-width: 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            height: 50px;
            box-shadow: 0px 0px 5px #0000005a;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: 40px auto 0px;
            @media (max-width: 575px) {
                height: 40px;
                max-width: 100%;
                width: 100%;
            }
            .nav-item {
                max-width: max-content;
                min-width: 150px;
                text-transform: uppercase;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #363795;
                font-weight: bold;
                padding: 0 15px;
                @media (max-width: 575px) {
                    max-width: unset;
                    // width: auto;
                    // height: auto;
                    font-size: 16px;
                    padding: 0 10px;
                    min-width: unset;
                    line-height: 110%;
                }
                @media (max-width: 575px) {
                    max-width: unset;
                    // width: auto;
                    // height: auto;
                    font-size: 14px;
                    line-height: 110%;
                    padding: 0 10px;
                    min-width: unset;
                }
                &.tab-active {
                    background-image: linear-gradient(
                        to right,
                        #363795,
                        #0040ff
                    );
                    color: #ffffff;
                }
                cursor: pointer;
                &:first-child {
                    border-top-left-radius: 20px;
                }
                &:last-child {
                    border-top-right-radius: 20px;
                }
            }
        }
        .box-content {
            min-height: 500px;
            height: auto;
            box-shadow: 5px 0px 15px 0px rgba($color: #000000, $alpha: 0.3);
            border-radius: 15px;
            // overflow: hidden;
            padding: 0;
            display: flex;

            .left {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                .top {
                    flex: 1 1 auto;

                    p {
                        margin-bottom: 5px;
                        padding: 0 25px;
                        font-weight: 500;
                    }
                    .colum-2 {
                        padding: 20px 0;
                    }
                }
                .center {
                    flex: 1 1 auto;
                    border-top: 4px solid #d4ebff;
                    border-bottom: 4px solid #d4ebff;
                    p {
                        margin-bottom: 0;
                        text-align: center;
                        font-weight: 900;
                    }
                    .colum-2 {
                        padding: 10px 0;
                    }
                }
                .bottom {
                    flex: 1 1 auto;
                }
                .top,
                .center,
                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // overflow: hidden;
                    .content-left {
                        flex: 0 0 30%;
                        height: 100%;
                        border-right: 4px solid #d4ebff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'Roboto', sans-serif;
                        font-size: 22px;
                        color: #003189;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                        @media (max-width: 991px) {
                            font-size: 19px;
                        }
                        @media (max-width: 767px) {
                            font-size: 17px;
                        }
                    }
                    .content-right {
                        height: 100%;
                        flex: 0 0 70%;
                        display: flex;
                        // overflow: hidden;
                        .colum-1 {
                            height: 100%;
                            flex: 0 0 40%;
                        }
                        .colum-3 {
                            height: 100%;
                            flex: 0 0 37%;
                        }
                        .colum-1 {
                            border-right: 4px solid #d4ebff;
                            .item {
                                border-top: 4px solid #d4ebff;
                            }
                        }
                        .colum-3 {
                            border-left: 4px solid #d4ebff;
                        }
                        .colum-2 {
                            height: 100%;
                            flex: 1 1 auto;

                            display: flex;
                            justify-content: center;
                            // align-items: center;
                            flex-direction: column;
                        }
                        .header-col {
                            height: 40px;
                            background: linear-gradient(
                                172deg,
                                #237fff 0%,
                                #0930ff 100%
                            );
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            font-display: 'Roboto', sans-serif;
                            font-weight: 600;
                            font-size: 15px;
                            text-align: center;
                            padding: 5px 0;
                            line-height: 1;
                        }
                        .content {
                            height: calc(100% - 40px);
                            display: flex;
                            flex-direction: column;
                            .item {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 500;
                                text-align: center;
                                padding: 5px;
                            }
                        }
                    }
                }
            }
            .right {
                flex: 0 0 175px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                overflow: hidden;
                border-radius: 0 15px 15px 0;
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                @media (max-width: 991px) {
                    flex: 0 0 150px;
                }
                .logo {
                    width: 80%;
                    height: auto;
                    margin: 15px auto;
                    position: relative;
                    z-index: 10;
                }
                .mock {
                    position: absolute;
                    width: 300px;
                    object-fit: cover;
                    object-position: center;
                    bottom: 0;
                }
            }
            @media (max-width: 767px) {
                flex-direction: column-reverse;
                .right {
                    border-radius: 15px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .logo {
                        height: 100px;
                        width: auto;
                        margin: 0 15px;
                    }
                    .mock {
                        right: 0;
                        top: 0;
                        margin: auto;
                        height: 100%;
                        width: 200px;
                    }
                }
                .left {
                    .center {
                        border-top: 4px solid #3f51b5;
                        border-bottom: 4px solid #3f51b5;
                    }
                    .top,
                    .center,
                    .bottom {
                        flex-direction: column;
                        // overflow: hidden;
                        .content-left {
                            flex: 0 0 100%;
                            height: auto;
                            min-height: 50px;
                            width: 100%;
                            border-right: 0;
                            border-bottom: 4px solid #d4ebff;
                        }
                        .content-right {
                            height: 100%;
                            flex: 0 0 100%;
                            width: 100%;
                            display: flex;
                            // overflow: hidden;
                            .colum-1 {
                                height: auto;
                                flex: 0 0 40%;
                            }
                            .colum-3 {
                                height: auto;
                                flex: 0 0 37%;
                            }
                            .colum-1 {
                                border-right: 4px solid #d4ebff;
                                .item {
                                    border-top: 4px solid #d4ebff;
                                }
                            }
                            .colum-3 {
                                border-left: 4px solid #d4ebff;
                            }
                            .colum-4 {
                                border-left: 4px solid #d4ebff;
                            }
                            .colum-2 {
                                height: auto;
                            }
                            .content {
                                height: calc(100% - 40px);
                                display: flex;
                                flex-direction: column;
                                .item {
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 500;
                                    text-align: center;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
            @media (max-width: 575px) {
                .left {
                    .top,
                    .center,
                    .bottom {
                        .content-right {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        .box-content2 {
            min-height: 500px;
            height: auto;
            box-shadow: 5px 0px 15px 0px rgba($color: #000000, $alpha: 0.3);
            border-radius: 15px;
            // overflow: hidden;
            padding: 0;
            display: flex;

            .left {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                .top {
                    flex: 1 1 auto;

                    p {
                        margin-bottom: 5px;
                        padding: 0 25px;
                        font-weight: 500;
                    }
                    .colum-2 {
                        padding: 20px 0;
                    }
                }
                .center {
                    flex: 1 1 auto;
                    border-top: 4px solid #d4ebff;
                    border-bottom: 4px solid #d4ebff;
                    p {
                        margin-bottom: 0;
                        text-align: center;
                        font-weight: 900;
                    }
                    .colum-2 {
                        padding: 10px 0;
                    }
                }
                .bottom {
                    flex: 1 1 auto;
                }
                .top,
                .center,
                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // overflow: hidden;
                    .content-left {
                        flex: 0 0 30%;
                        height: 100%;
                        border-right: 4px solid #d4ebff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'Roboto', sans-serif;
                        font-size: 22px;
                        color: #003189;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                        @media (max-width: 991px) {
                            font-size: 19px;
                        }
                        @media (max-width: 767px) {
                            font-size: 17px;
                        }
                    }
                    .content-right {
                        height: auto;
                        flex: 0 0 70%;
                        display: flex;
                        overflow-x: auto;
                        overflow-y: hidden;
                        .colum-1 {
                            height: 100%;
                            flex: 0 0 40%;
                            min-width: 250px;
                        }
                        .colum-3 {
                            min-width: 200px;
                            height: 100%;
                            flex: 0 0 25%;
                        }
                        .colum-1 {
                            border-right: 4px solid #d4ebff;
                            .item {
                                border-top: 4px solid #d4ebff;
                            }
                        }
                        .colum-3 {
                            border-left: 4px solid #d4ebff;
                            .item {
                                border-top: 4px solid #d4ebff;
                            }
                        }
                        .colum-4 {
                            min-width: 100px;
                            border-left: 4px solid #d4ebff;
                            flex: 1 1 auto;
                            .item {
                                border-top: 4px solid #d4ebff;
                            }
                        }
                        .colum-2 {
                            min-width: 100px;
                            height: 100%;
                            flex: 1 1 auto;
                            display: flex;
                            justify-content: center;
                            // align-items: center;
                            flex-direction: column;
                            .item {
                                border-top: 4px solid #d4ebff;
                            }
                        }
                        .header-col {
                            min-height: 55px;
                            background: linear-gradient(
                                172deg,
                                #237fff 0%,
                                #0930ff 100%
                            );
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            font-display: 'Roboto', sans-serif;
                            font-weight: 600;
                            font-size: 15px;
                            text-align: center;
                            padding: 5px 0;
                            line-height: 1;
                        }
                        .content {
                            display: flex;
                            flex-direction: column;
                            .item {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 500;
                                text-align: center;
                                padding: 5px;
                                min-height: 55px;
                            }
                        }
                    }
                }
            }
            .right {
                flex: 0 0 175px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                overflow: hidden;
                border-radius: 0 15px 15px 0;
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                @media (max-width: 991px) {
                    flex: 0 0 150px;
                }
                .logo {
                    width: 80%;
                    height: auto;
                    margin: 15px auto;
                    position: relative;
                    z-index: 10;
                }
                .mock {
                    position: absolute;
                    width: 300px;
                    object-fit: cover;
                    object-position: center;
                    bottom: 0;
                }
            }
            @media (max-width: 767px) {
                flex-direction: column-reverse;
                .right {
                    border-radius: 15px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .logo {
                        height: 100px;
                        width: auto;
                        margin: 0 15px;
                    }
                    .mock {
                        right: 0;
                        top: 0;
                        margin: auto;
                        height: 100%;
                        width: 200px;
                    }
                }
                .left {
                    .center {
                        border-top: 4px solid #3f51b5;
                        border-bottom: 4px solid #3f51b5;
                    }
                    .top,
                    .center,
                    .bottom {
                        flex-direction: column;
                        // overflow: hidden;
                        .content-left {
                            flex: 0 0 100%;
                            height: auto;
                            min-height: 50px;
                            width: 100%;
                            border-right: 0;
                            border-bottom: 4px solid #d4ebff;
                        }
                        .content-right {
                            height: 100%;
                            flex: 0 0 100%;
                            width: 100%;
                            display: flex;
                            // overflow: hidden;
                            .colum-1 {
                                height: auto;
                                flex: 0 0 40%;
                            }
                            .colum-3 {
                                height: auto;
                                flex: 0 0 37%;
                            }
                            .colum-1 {
                                border-right: 4px solid #d4ebff;
                                .item {
                                    border-top: 4px solid #d4ebff;
                                }
                            }
                            .colum-3 {
                                border-left: 4px solid #d4ebff;
                            }
                            .colum-2 {
                                height: auto;
                            }
                            .content {
                                // height: calc(100% - 40px);
                                display: flex;
                                // flex-direction: column;
                                .item {
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 500;
                                    text-align: center;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
            @media (max-width: 575px) {
                .left {
                    .top,
                    .center,
                    .bottom {
                        .content-right {
                            // flex-direction: column;
                        }
                    }
                }
            }
        }
        .warning {
            max-width: 90%;
            margin: 40px auto;
            @media (max-width: 575px) {
                max-width: 100%;
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 30px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 10px;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                @media (max-width: 767px) {
                    font-size: 19px;
                }
            }
            p {
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
}
</style>
